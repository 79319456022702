<template>
  <path id="Tracé_563" data-name="Tracé 563" d="M14.107,58.533A31.994,31.994,0,0,1,31.91,0a1.685,1.685,0,1,1,.01,3.37,28.564,28.564,0,1,0,7.487.967,1.685,1.685,0,0,1,.873-3.255A32,32,0,1,1,14.107,58.533Zm13.049-10.66-12.278-14.8s0,0,0,0,0-.005-.005-.01a.564.564,0,0,1-.056-.076l-.04-.048a.019.019,0,0,0,0-.01.417.417,0,0,1-.044-.077.374.374,0,0,1-.035-.055.042.042,0,0,1-.008-.014.663.663,0,0,1-.032-.068c-.01-.025-.022-.045-.031-.07,0,0,0-.007-.005-.014a.49.49,0,0,1-.023-.064.438.438,0,0,1-.025-.076.062.062,0,0,0-.006-.017l-.011-.047a.059.059,0,0,1-.006-.027.334.334,0,0,1-.019-.07s0-.009,0-.014,0-.029-.007-.044-.006-.043-.01-.062a.294.294,0,0,1-.005-.045v-.007a.323.323,0,0,1,0-.067c0-.029,0-.059,0-.093v0c0-.032,0-.062,0-.094a.289.289,0,0,1,0-.064v-.01a.369.369,0,0,1,.005-.044c0-.02.006-.041.01-.062s0-.029.007-.045a.117.117,0,0,0,0-.014.358.358,0,0,1,.019-.069.062.062,0,0,1,.006-.027l.011-.047a.084.084,0,0,0,.006-.018.5.5,0,0,1,.025-.076.517.517,0,0,1,.023-.062.034.034,0,0,1,.005-.015c.01-.023.022-.044.031-.068s.021-.047.032-.07l.008-.014.032-.054a.842.842,0,0,1,.047-.077.016.016,0,0,0,0-.008c.014-.017.025-.032.04-.049a.528.528,0,0,1,.056-.077s.005-.005.005-.01,0,0,0,0l12.278-14.8a1.685,1.685,0,0,1,2.594,2.15l-9.988,12.04H47.822a1.686,1.686,0,1,1,0,3.371H19.761L29.75,45.725a1.684,1.684,0,1,1-2.594,2.148Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconPrecedent"
}
</script>

<style scoped>

</style>